import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export function CreateTable(produtos: any[]): void {
  const TableHeader =
    '<tr><th style="width: 80px">Seq</th><th style="text-align: left">Produto</th><th style="text-align: left">Ocorrência</th></tr>';
  const TableBody = produtos.map((item: any): string => {
    const spaco = '&nbsp;&nbsp;';
    return `<tr><td>${item.num_sequencia}${spaco}${spaco}</td><td style="text-align: left">${item.des_produto}${spaco}</td><td style="text-align: left">${item.ocorrencia}</td></tr>`;
  });

  const Table = `
          <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto; text-align: left; padding: 10px'>
            <table style='width:100%;min-width: 500px'>
              <thead>${TableHeader}</thead>
              <tbody>${TableBody.join(' ')}</tbody>
            </table>
          </div>
          <p style='text-align: left; padding: 20px'>

          </p>
          `;
  if (produtos.length > 0) {
    MySwal.fire({
      icon: 'warning',
      width: 800,
      title: 'Regularize a situação para prosseguir',
      html: String(Table),
    });
  }
}
