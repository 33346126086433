import { DecomposicaoData } from '../types';

interface ValidateItemsResponse {
  num_sequencia: number;
  des_produto: string;
  ocorrencia: string;
}

export const validateItems = (
  items: DecomposicaoData[],
  isUnitaryType: boolean,
): ValidateItemsResponse[] => {
  return items.flatMap((item) => {
    if (isUnitaryType && item.flg_ipv) {
      return {
        num_sequencia: item.num_sequencia,
        des_produto: item.des_produto,
        ocorrencia: 'Item não pode ser pesável',
      };
    }

    if (Number(item.qtd_decomp) <= 0) {
      return {
        num_sequencia: item.num_sequencia,
        des_produto: item.des_produto,
        ocorrencia: 'A quantidade deve ser maior que zero',
      };
    }
    if (isUnitaryType) {
      if (
        item.per_custo_kit === 0 ||
        String(item.per_custo_kit) === '' ||
        item.per_custo_kit === undefined ||
        item.per_custo_kit === null ||
        String(item.per_custo_kit) === '0,000'
      ) {
        return {
          num_sequencia: item.num_sequencia,
          des_produto: item.des_produto,
          ocorrencia: 'O custo informado deve ser maior que zero',
        };
      }
    }

    return [];
  });
};
