import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  CadastroDecomposicaoContextData,
  Decomposicao,
  ProviderCadDecomposicaoProps,
  TipoDecomposicao,
} from './types';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validation';

export const initialFormData: Decomposicao = {
  cod_loja: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  cod_produto: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  des_produto: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  tipo_forma_explosao: {
    value: 0,
    isInvalid: false,
    isRequired: false,
  },
  qtd_decomp: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
  val_custo_inicial: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
  per_custo_kit: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
};

export const CadastroDecomposicaoContext = createContext(
  {} as CadastroDecomposicaoContextData,
);

export const CadastroDecomposicaoProvider: React.FC<
  ProviderCadDecomposicaoProps
> = ({ children }) => {
  const [formData, setFormDataState] = useState<Decomposicao>(initialFormData);
  const [isExplosionPurchase, setIsExplosionPurchaseState] = useState(false);
  const [isUpdate, setUpdateState] = useState(false);
  const [tipoFormaExplosao, setTipoFormaExplosao] = useState<number>(0);

  const isUnitaryType =
    formData.tipo_forma_explosao.value === TipoDecomposicao.UNITARIO;

  const [totalQtde, setTotalQtd] = useState(0);
  const [saldoQtde, setSaldoQtd] = useState(100);
  const [updateItems, setUpdateItems] = useState<boolean>(false);

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    getValues,
    control,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setValue('isUnitaryType', isUnitaryType);
    if (!formData.qtd_decomp && !isUnitaryType) {
      setTotalQtd(0);
      setSaldoQtd(100);
    }
  }, [formData.qtd_decomp, isUnitaryType]);

  const setFormData = useCallback(
    (value: Decomposicao) => setFormDataState(value),
    [setFormDataState],
  );

  const setTotalQtde = useCallback(
    (value: number) => setTotalQtd(value),
    [setTotalQtd],
  );

  const setSaldoQtde = useCallback(
    (value: number) => setSaldoQtd(value),
    [setSaldoQtd],
  );

  const setIsExplosionPurchase = useCallback(
    (value: boolean) => setIsExplosionPurchaseState(value),
    [setIsExplosionPurchaseState],
  );

  const setUpdate = useCallback(
    (value: boolean) => setUpdateState(value),
    [setUpdateState],
  );

  return (
    <CadastroDecomposicaoContext.Provider
      value={{
        formData,
        setFormData,
        setIsExplosionPurchase,
        isExplosionPurchase,
        totalQtde,
        saldoQtde,
        setTotalQtde,
        setSaldoQtde,
        isUnitaryType,
        setUpdate,
        isUpdate,
        tipoFormaExplosao,
        setTipoFormaExplosao,

        register,
        reset,
        setValue,
        handleSubmit,
        clearErrors,
        getValues,
        control,
        setFocus,
        setError,
        errors,
        updateItems,
        setUpdateItems,
      }}
    >
      {children}
    </CadastroDecomposicaoContext.Provider>
  );
};

export const useCadastroDecomposicao = (): CadastroDecomposicaoContextData =>
  useContext(CadastroDecomposicaoContext);
