import React from 'react';

import { useCadastroDecomposicao } from '../CadastroDeDecomposicaoContext';

export const TableForm: React.FC = () => {
  const { formData } = useCadastroDecomposicao();
  const { cod_loja, cod_produto, des_produto } = formData;

  const codLoja = cod_loja.value;
  const codProduto = cod_produto.value;
  const desProduto = des_produto.value;

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Loja</th>
          <th>PLU</th>
          <th>Produto</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{codLoja}</td>
          <td>{codProduto}</td>
          <td>{desProduto}</td>
        </tr>
      </tbody>
    </table>
  );
};
