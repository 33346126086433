import { transformAsCurrency } from '~/utils/functions';
import * as yup from 'yup';

export const schema = yup
  .object({
    per_custo_kit: yup.string().when('isUnitaryType', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup
        .string()
        .required()
        .test({
          name: 'isPercentual',
          test: (val) => {
            const valueToFloat = transformAsCurrency(val || 0);
            return valueToFloat <= 100 && valueToFloat > 0;
          },
          message: 'Per_custo_kit deve ser um percentual entre 0 e 100',
        }),
      otherwise: yup.string().test({
        name: 'isPercentual',
        test: (val) => {
          const valueToFloat = transformAsCurrency(val || 0);
          return !val || (valueToFloat <= 100 && valueToFloat >= 0);
        },
        message: 'Per_custo_kit deve ser um percentual entre 0 e 100',
      }),
    }),
    qtd_decomp: yup
      .string()
      .required()
      .test({
        name: 'isValorNormal',
        test: (val) => {
          const valueToFloat = transformAsCurrency(val || 0);
          return valueToFloat <= 100 && valueToFloat > 0;
        },
        message: 'Qtd_decomp deve ser um valor entre 0 e 100',
      }),
  })
  .required();
