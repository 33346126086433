import api from '~/services/api';
import { toast } from 'react-toastify';

import {
  DecomposicaoDataResponseProps,
  DecomposicaoResponseProps,
} from '../types';

export const getDecomposition = async (
  codLoja: number | string,
  codProduto: number | string,
): Promise<DecomposicaoDataResponseProps[] | null | undefined> => {
  try {
    const { data: response } = await api.get<DecomposicaoResponseProps>(
      `/cadastro-de-decomposicao/${codLoja}/${codProduto}`,
    );

    if (!response.success) return null;

    return response.data;
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.warning(error.data.message);
      return null;
    }
    toast.warning(String(error));
  }
};
