import { transformAsCurrency } from '~/utils/functions';

import { DecomposicaoData } from '../types';

export const calculaTotalSaldoQtde = (
  data: DecomposicaoData[],
  field: 'per_custo_kit' | 'qtd_decomp' | 'val_custo_inicial',
): number =>
  data.reduce(
    (acc: number, item: DecomposicaoData) =>
      acc + transformAsCurrency(item[field]),
    0,
  );
