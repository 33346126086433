import React from 'react';

import CadastroDeDecomposicaoContent from './CadastroDeDecomposicaoContent';
import { CadastroDecomposicaoProvider } from './CadastroDeDecomposicaoContext';

const CadastroDeDecomposicao: React.FC = () => {
  return (
    <CadastroDecomposicaoProvider>
      <CadastroDeDecomposicaoContent />
    </CadastroDecomposicaoProvider>
  );
};

export default CadastroDeDecomposicao;
