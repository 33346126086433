import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import React, { useCallback } from 'react';

import { useCadastroDecomposicao } from '../CadastroDeDecomposicaoContext';
import { TipoDecomposicao } from '../types';

type SelectProps = {
  label: string;
  value: number;
};

const options: SelectProps[] = [
  { label: 'Fracionado', value: TipoDecomposicao.FRACIONADO },
  { label: 'Unitário', value: TipoDecomposicao.UNITARIO },
];

export const SelectTipoDecomposicao: React.FC = () => {
  const {
    formData,
    setFormData,
    isExplosionPurchase,
    reset,
    setUpdateItems,
    getValues,
    setValue,
  } = useCadastroDecomposicao();

  const { tipo_forma_explosao } = formData;

  const handleSelectChange = useCallback(
    (selectedOption) => {
      if (selectedOption) {
        const selectedValue = +selectedOption.value;

        setFormData({
          ...formData,
          tipo_forma_explosao: {
            value: selectedValue,
            isInvalid: false,
            isRequired: false,
          },
        });
        const decomposicaoVal = getValues('decomposicaoVal');
        reset();
        setValue('decomposicaoVal', decomposicaoVal);
        setValue('produtoBusca', { value: undefined, label: '' });
        setUpdateItems(false);
      }
    },
    [setFormData, formData],
  );

  return (
    <Field
      name="tipo_decomposicao"
      defaultValue={tipo_forma_explosao.value}
      label="Tipo"
    >
      {() => (
        <>
          <Select
            placeholder="Selecione um tipo"
            noOptionsMessage={() => 'Nenhum registro encontrado'}
            options={options}
            className="single-select"
            classNamePrefix="react-select"
            onChange={handleSelectChange}
            isDisabled={tipo_forma_explosao.isInvalid || !isExplosionPurchase}
            value={options.find(
              (option) => option.value === tipo_forma_explosao.value,
            )}
          />
        </>
      )}
    </Field>
  );
};
