import { formatCurrencyAsText } from '~/utils/functions';

const formatDate = (item: string | number | Date): string =>
  new Date(item).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

const formatDateTime = (item: string | number | Date): string =>
  new Date(item).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

const formatNumber = (item: number | bigint): string =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
    .format(item)
    .replace('R$', '');

const formatPercent = (item: string, isUnitary: boolean): string =>
  isUnitary ? `${formatCurrencyAsText(item)} %` : formatCurrencyAsText(item);

export const formatters = {
  formatDate,
  formatDateTime,
  formatNumber,
  formatPercent,
};
