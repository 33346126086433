import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .inputsContent {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .tamanhoBuscaProduto {
    #bProduto {
      width: 43.75rem;

      @media only screen and (max-width: 1399px) {
        width: 37.5rem;
      }

      @media only screen and (max-width: 1199px) {
        width: 33.125rem;
      }

      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  .buttonAdcionar {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;
  }

  .buttons {
    display: flex;
    min-width: 100px;
    max-width: 200px;
  }

  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const ContainerTable = styled.div`
  min-height: 250px;
  margin-top: 25px;
  @media (max-width: 1200px) {
    margin-bottom: 50px;
  }
  @media (max-width: 1000px) {
    margin-bottom: 100px;
  }
  @media (max-width: 600px) {
    margin-bottom: 200px;
  }
`;
export const ContainerAcoes = styled.div`
  width: 100px;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  transition: all 0.5s;
  button {
    background: none;
    border: none;
    svg {
      font-size: 20pt;
    }
  }
`;
export const ContainerTotais = styled.div`
  width: 100%;
  position: relative;
  min-height: 40px;
  background: #daebf6;
  font-weight: 600;
  padding: 10px;
  display: flex;
  align-items: center;
  @media (min-width: 1000px) {
    white-space: nowrap;
  }
  #linha-item-total {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .coluna-item-total {
    width: auto;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1.25rem;

  @media only screen and (max-width: 991px) {
    justify-content: space-between;
    gap: 0.625rem;
  }

  #qtd_decomp,
  #per_custo_kit,
  #val_custo_inicial {
    width: 13.125rem;

    @media only screen and (max-width: 1399px) {
      width: 10.625rem;
    }

    @media only screen and (max-width: 1199px) {
      width: 7.5rem;
    }

    @media only screen and (max-width: 991px) {
      width: 15.625rem;
    }

    @media only screen and (max-width: 768px) {
      width: 11.25rem;
    }
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const ConfirmButton = styled.button`
  padding: 3px 12px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  &:active,
  &:focus {
    border: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.confirm {
    @media (max-width: 800px) {
      width: 75px;
    }
    background-color: #28a745;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }

  &.cancel {
    @media (max-width: 800px) {
      width: 75px;
    }
    background-color: #dc3545;
    &:disabled {
      background-color: #e3bec1;
      cursor: not-allowed;
      &:hover {
        background-color: #e3bec1;
        opacity: 1;
      }
    }
  }

  &.clear {
    @media (max-width: 800px) {
      width: 75px;
    }
    background-color: #ffc107;
    &:disabled {
      background-color: #f1e1b0;
      cursor: not-allowed;
      &:hover {
        background-color: #f1e1b0;
        opacity: 1;
      }
    }
  }

  &:nth-child(2) {
    margin-left: 8px;
  }
`;
