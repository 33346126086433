import { ReactNode } from 'react';
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';

export interface DecomposicaoData {
  cod_produto_decompoe: string;
  des_produto: string;
  des_unidade: string;
  num_sequencia: number;
  qtd_decomp: number | string;
  val_custo_inicial: number;
  flg_ipv: boolean;
  per_custo_kit: number;
}

export interface Decomposicao {
  cod_produto: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_loja: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_forma_explosao: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_decomp: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_custo_inicial: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_custo_kit: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export interface ProviderCadDecomposicaoProps {
  children: ReactNode;
}

export interface CadastroDecomposicaoContextData {
  formData: Decomposicao;
  setFormData: (value: Decomposicao) => void;
  setIsExplosionPurchase: (value: boolean) => void;
  isExplosionPurchase: boolean;
  setTotalQtde: (value: number) => void;
  setSaldoQtde: (value: number) => void;
  totalQtde: number;
  saldoQtde: number;
  isUnitaryType: boolean;
  setUpdate: (value: boolean) => void;
  isUpdate: boolean;

  tipoFormaExplosao: number;
  setTipoFormaExplosao: (value: number) => void;

  register: UseFormRegister<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  control: Control<FieldValues, any>;
  setFocus: UseFormSetFocus<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;

  updateItems: boolean;
  setUpdateItems: (value: boolean) => void;
}

// eslint-disable-next-line no-shadow
export enum TipoDecomposicao {
  FRACIONADO = 0,
  UNITARIO = 1,
}

export type DecomposicaoDataResponseProps = {
  cod_gtin_principal: string;
  cod_produto_decompoe: string;
  cod_produto_pai: string;
  cod_seq_produto_decomposicao: number;
  des_produto: string;
  des_unidade: string;
  flg_ipv: boolean;
  qtd_decompoe: number;
  total_qtd_decompoe: number;
  total_val_custo_inicial: number;
  val_custo_inicial: number;
  per_custo_kit: number;
};

export type DecomposicaoResponseProps = {
  count?: number;
  data: DecomposicaoDataResponseProps[];
  fields?: any[];
  message: string;
  pagination?: {
    prevPage: number;
    lastPage: number;
    startPage: number;
  };
  success: boolean;
};

export type CreateDecompositionProps = {
  cod_loja?: string | number;
  cod_produto?: string | number;
  itens: DecomposicaoData[];
};

export type TProdutoProps = {
  cod_produto: string;
  des_produto: string;
};
