import Spinner from '@atlaskit/spinner';
import { GridRowParams } from '@material-ui/data-grid';
import FormDefault from '~/components/FormDefault';
import { BuscaProduto, InputNumber, InputPeso } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsTrashFill } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { CreateTable } from './_components/CreateTable';
import { SelectTipoDecomposicao } from './_components/SelectTipoDecomposicao';
import { TableForm } from './_components/TableForm';
import {
  initialFormData,
  useCadastroDecomposicao,
} from './CadastroDeDecomposicaoContext';
import CustomDataGrid, { CustomGridColumns } from './CustomDataGrid';
import { createDecomposition } from './services/createDecomposicao';
import { getDecomposition } from './services/getDecomposicao';
import {
  Container,
  ContainerAcoes,
  ContainerInput,
  ContainerTable,
  ContainerTotais,
} from './styles';
import { CreateDecompositionProps, DecomposicaoData } from './types';
import { calculaTotalQtd } from './utils/calculaTotalQtd';
import { calculaTotalSaldoQtde } from './utils/calculaTotalSaldoQtde';
import { delayDecomposition } from './utils/delayDecomposition';
import { ordernacaoItem } from './utils/ordernacaoItem';
import { validateItems } from './utils/validateItems';
import { CreateTableBuscaProduto } from './_components/CreateTableBuscaProduto';
import { CancelButton, ConfirmButton } from '~/components/Buttons';

const MySwal = withReactContent(Swal);

const CadastroDeDecomposicaoContent: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTable, setloaderTable] = useState<boolean>(false);
  const [idUpdate, setIdUpdate] = useState<number | undefined>(undefined);

  const [decomposicao, setDecomposicao] = useState<DecomposicaoData[]>([]);
  const [showSearch, setShowSearch] = useState(true);
  const [decomposicaoFracionada, setDecomposicaoFracionada] = useState<
    DecomposicaoData[]
  >([]);
  const [decomposicaoUnitaria, setDecomposicaoUnitaria] = useState<
    DecomposicaoData[]
  >([]);
  const [itemInserido, setItemInserido] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonAddRef = useRef<HTMLButtonElement | null>(null);

  const {
    formData,
    setFormData,
    setIsExplosionPurchase,
    isUnitaryType,
    totalQtde,
    setTotalQtde,
    setSaldoQtde,
    saldoQtde,
    isUpdate,
    setUpdate,
    isExplosionPurchase,
    setTipoFormaExplosao,
    tipoFormaExplosao,

    register,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    getValues,
    control,
    setFocus,
    setError,
    errors,

    updateItems,
    setUpdateItems,
  } = useCadastroDecomposicao();

  const commonResetOperations = useCallback(() => {
    reset();
    setValue('tipo_explosao_compra', tipoFormaExplosao);
    setTotalQtde(0);
    setSaldoQtde(100);
    setValue('totalQtdeVal', 0);
    setValue('decomposicaoVal', []);
    setDecomposicao([]);
    setDecomposicaoUnitaria([]);
    setDecomposicaoFracionada([]);
  }, [reset, setSaldoQtde, setTotalQtde, setValue, tipoFormaExplosao]);

  const resetFormData = useCallback(() => {
    commonResetOperations();
    setFormData(initialFormData);
  }, [commonResetOperations, setFormData]);

  const handleUpdateProduto = handleSubmit(async (data: any) => {
    if (data.val_custo_inicial === '') {
      data.val_custo_inicial = 0;
    }
    if (idUpdate !== undefined) {
      const qtde = getValues('qtd_decomp');

      if (qtde < 0 || data?.per_custo_kit === '') {
        return toast.warning(
          'Os valores de Quantidade e Custo devem ser informados.',
        );
      }

      const items = Array.from(decomposicao);
      const idx = items.findIndex((item) => item.num_sequencia === idUpdate);

      if (idx !== -1) {
        const itemToUpdate = items[idx];

        itemToUpdate.qtd_decomp = data.qtd_decomp;
        if (!isUnitaryType) {
          itemToUpdate.val_custo_inicial = data.val_custo_inicial;
        } else {
          itemToUpdate.per_custo_kit = data.per_custo_kit;
        }

        const totalQtd = calculaTotalQtd(items);

        const field = isUnitaryType ? 'per_custo_kit' : 'qtd_decomp';
        const total = calculaTotalSaldoQtde(items, field);

        const totalQtdeValue = isUnitaryType ? total : totalQtd;

        setTotalQtde(totalQtdeValue);

        if (isUnitaryType && saldoQtde < 0) {
          const totalWithUpdatedItem = calculaTotalSaldoQtde(items, field);
          setSaldoQtde(100 - totalWithUpdatedItem);
        } else {
          setSaldoQtde(100 - total);
        }

        const updatedItemsWithSequence = items.map((item, i) => ({
          ...item,
          num_sequencia: i + 1,
        }));

        setDecomposicao(updatedItemsWithSequence);
        setUpdateItems(false);
        reset();
        setValue('isUnitaryType', isUnitaryType);
        setValue('tipo_explosao_compra', tipoFormaExplosao);

        setItemInserido(true);

        await delayDecomposition(500);

        setItemInserido(false);
        setValue('decomposicaoVal', updatedItemsWithSequence);
        setValue('totalQtdeVal', totalQtdeValue);
      }
    }
  });

  const updateItem = (item: DecomposicaoData) => {
    setUpdateItems(true);

    setValue('produtoBusca', {
      label: `${item.cod_produto_decompoe} - ${item.des_produto}`,
      value: item.cod_produto_decompoe,
    });

    const field = isUnitaryType ? 'per_custo_kit' : 'val_custo_inicial';
    const value = isUnitaryType ? item.per_custo_kit : item.val_custo_inicial;

    const checkType =
      typeof item.val_custo_inicial === 'number' ||
      typeof item.per_custo_kit === 'number';

    const valueFormatted = formatCurrencyAsText(value);

    if (isUnitaryType) {
      setValue('qtd_decomp', Math.round(transformAsCurrency(item.qtd_decomp)));
    } else {
      setValue(
        'qtd_decomp',
        formatCurrencyAsText(transformAsCurrency(item.qtd_decomp), 3),
      );
    }
    setValue(field, checkType ? valueFormatted : value);
    setIdUpdate(item.num_sequencia);
  };

  const deleteItem = (id: number) => {
    MySwal.fire({
      title: `Deseja realmente excluir esse Produto?`,
      text: ``,
      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const idx = decomposicao.findIndex((item) => item.num_sequencia === id);

        if (idx !== -1) {
          decomposicao.splice(idx, 1);

          const itensOrdenados = ordernacaoItem(decomposicao);
          const totalQtd = calculaTotalQtd(decomposicao);

          if (itensOrdenados.length <= 0) {
            commonResetOperations();
            await delayDecomposition(500);
            return;
          }

          const field = isUnitaryType ? 'per_custo_kit' : 'qtd_decomp';

          const total = calculaTotalSaldoQtde(itensOrdenados, field);

          setTotalQtde(totalQtd);
          setSaldoQtde(100 - total);
          setDecomposicao(itensOrdenados);
          setUpdateItems(false);
          reset();
          setValue('totalQtdeVal', totalQtd);
          setValue('decomposicaoVal', itensOrdenados);
          setValue('tipo_explosao_compra', tipoFormaExplosao);
          setValue('isUnitaryType', isUnitaryType);
          clearErrors('qtd_decomp');
          clearErrors('val_custo_inicial');
          clearErrors('produtoBusca');
        }
      }
    });
  };

  const columns: CustomGridColumns[] = [
    {
      field: 'num_sequencia',
      headerName: 'Item',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_produto_decompoe',
      headerName: 'PLU',
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'qtd_decomp',
      headerName: isUnitaryType ? 'Qtde' : 'Qtde %',
      width: 125,
      sortable: false,
      disableColumnMenu: true,
      format: 'qtd',
    },
    {
      field: 'des_unidade',
      headerName: 'UN',
      width: 125,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: isUnitaryType ? 'per_custo_kit' : 'val_custo_inicial',
      headerName: isUnitaryType ? '% Custo' : 'Custo Inicial',
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      format: 'percent',
      align: 'right',
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'right',
      width: 100,
      renderCell: (row: any) => {
        return (
          <ContainerAcoes>
            <button type="button" onClick={() => updateItem(row)}>
              <MdEdit color="#FE9901" />
            </button>
            <button
              type="button"
              onClick={() => {
                setUpdateItems(false);
                deleteItem(row.num_sequencia);
              }}
            >
              <BsTrashFill color="#CF2C2D" />
            </button>
          </ContainerAcoes>
        );
      },
    },
  ];

  const validaInputsBuscaProduto = () => {
    const { per_custo_kit } = getValues();

    const qtde = getValues('qtd_decomp');

    if (isUnitaryType) {
      if (qtde < 0 || per_custo_kit === '') {
        setError('per_custo_kit', { type: 'required' });
        toast.warning('Os valores de Quantidade e Custo devem ser informados.');
        return true;
      }
    }

    return false;
  };

  const handleAddProduto = handleSubmit(async (data: any) => {
    try {
      if (!data.val_custo_inicial) {
        data.val_custo_inicial = 0;
      }
      setloaderTable(true);

      const { val_custo_inicial, qtd_decomp, per_custo_kit, produto_busca } =
        data;

      if (validaInputsBuscaProduto()) {
        return;
      }

      if (!data.produtoBusca) {
        return toast.warning('Produto deve ser informado');
      }

      const productsWithErrors: any[] = [];
      let products = produto_busca.map((item: any) => {
        if (item.cod_associado) {
          item.num_sequencia = productsWithErrors.length + 1;
          item.ocorrencia =
            'Produto associado! Não é permitido incluir na Decomposição.';
          productsWithErrors.push({
            ...item,
          });
          return;
        }

        if (item.flg_ipv && isUnitaryType) {
          item.num_sequencia = 0;
          item.ocorrencia = 'Item não pode ser pesável.';

          productsWithErrors.push({
            ...item,
          });
          return;
        }

        if (formData.cod_produto.value === item.value) {
          item.num_sequencia = productsWithErrors.length + 1;
          item.ocorrencia =
            'Produto a ser incluído é o mesmo da decomposição. Pesquise outro';
          productsWithErrors.push({
            ...item,
          });
          return;
        }
        if (item.tipo_movimentacao !== 0) {
          item.num_sequencia = productsWithErrors.length + 1;
          item.ocorrencia =
            'Apenas produtos de movimentação Direta podem ser incluídos à uma Decomposição!';
          productsWithErrors.push({
            ...item,
          });
          return;
        }

        return item;
      });

      products = products.filter((item: any) => item !== undefined);

      if (produto_busca.length > 1 && productsWithErrors.length > 0) {
        CreateTableBuscaProduto(productsWithErrors);
      }

      if (produto_busca.length === 1 && productsWithErrors.length > 0) {
        return toast.warning(productsWithErrors[0].ocorrencia);
      }

      if (produto_busca[0].tipo_movimentacao !== 0) {
        return toast.warning(
          'Apenas produtos de movimentação Direta podem ser incluídos à uma Decomposição!',
        );
      }

      const decomp = Array.from(decomposicao);

      const produtosDecomp = decomp.map((item) => item.cod_produto_decompoe);
      const commonItemsDecomp = products.filter((item: any) => {
        return produtosDecomp.includes(item.cod_produto_decompoe);
      });

      if (commonItemsDecomp.length > 0) {
        commonItemsDecomp.map((item: any) => {
          const indexAlterar = decomp.findIndex(
            (indx) => indx.cod_produto_decompoe === item.cod_produto_decompoe,
          );
          const indexApagarBuscaProduto = products.findIndex(
            (indx: any) =>
              indx.cod_produto_decompoe === item.cod_produto_decompoe,
          );

          products.splice(indexApagarBuscaProduto, 1);

          if (indexAlterar !== -1) {
            const produtos = {
              num_sequencia: 1,
              cod_produto_decompoe: item.value,
              qtd_decomp,
              des_produto: item.des_produto,
              des_unidade: item.des_unidade,
              val_custo_inicial: val_custo_inicial || 0,
              flg_ipv: item.flg_ipv,
              per_custo_kit: per_custo_kit || 0,
            };

            decomp[indexAlterar] = produtos;
          }

          return false;
        });
      }

      if (products.length > 0) {
        products.map((item: any) => {
          const produtos = {
            num_sequencia: 1,
            cod_produto_decompoe: item.value,
            qtd_decomp,
            des_produto: item.des_produto,
            des_unidade: item.des_unidade,
            val_custo_inicial: val_custo_inicial || 0,
            flg_ipv: item.flg_ipv,
            per_custo_kit: per_custo_kit || 0,
          };
          decomp.push(produtos);

          return produtos;
        });
      } else if (products.length !== 0) {
        const produtos: DecomposicaoData = {
          num_sequencia: 1,
          cod_produto_decompoe: products[0].value,
          qtd_decomp,
          des_produto: products[0].des_produto,
          des_unidade: products[0].des_unidade,
          val_custo_inicial: val_custo_inicial || 0,
          flg_ipv: products[0].flg_ipv,
          per_custo_kit: per_custo_kit || 0,
        };
        decomp.push(produtos);
      }

      for (let i = 0; i < decomp.length; i++) {
        decomp[i].num_sequencia = i + 1;
      }

      const totalQtd = calculaTotalQtd(decomp);

      const field = isUnitaryType ? 'per_custo_kit' : 'qtd_decomp';
      const totalSaldoValue = calculaTotalSaldoQtde(decomp, field);

      const totalQtdeValue = isUnitaryType ? totalSaldoValue : totalQtd;

      setTotalQtde(totalQtdeValue);
      setSaldoQtde(100 - totalSaldoValue);
      setValue('total', totalQtdeValue);
      setDecomposicao(decomp);
      setloaderTable(false);

      reset();
      setValue('isUnitaryType', isUnitaryType);
      setValue('tipo_explosao_compra', tipoFormaExplosao);
      setItemInserido(true);

      await delayDecomposition(500);

      setItemInserido(false);
      setValue('decomposicaoVal', decomp);
      setValue('totalQtdeVal', totalQtdeValue);

      clearErrors('qtd_decomp');
    } catch (error) {
      toast.warning(
        'Não foi possível adicionar, verifique se todos os dados foram devidamente informados.',
      );
    } finally {
      setloaderTable(false);
    }
  });

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_loja,
      cod_produto,
      des_produto,
      flg_explosao_compra,
      tipo_forma_explosao,
      qtd_decomp,
    } = row;

    setIsExplosionPurchase(flg_explosao_compra);
    setValue('decomposicaoVal', []);
    setDecomposicao([]);
    getDecomposicao(cod_loja, cod_produto);
    setValue('tipo_explosao_compra', tipo_forma_explosao);
    setTipoFormaExplosao(tipo_forma_explosao);
    setFormData({
      ...formData,
      cod_loja: {
        value: cod_loja,
        isInvalid: false,
        isRequired: false,
      },
      cod_produto: {
        value: cod_produto,
        isInvalid: false,
        isRequired: false,
      },
      des_produto: {
        value: des_produto,
        isInvalid: false,
        isRequired: false,
      },
      tipo_forma_explosao: {
        value: +tipo_forma_explosao,
        isInvalid: false,
        isRequired: false,
      },
      qtd_decomp: {
        value: qtd_decomp,
        isInvalid: false,
        isRequired: false,
      },
    });

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSubmit = useCallback(async () => {
    const { totalQtdeVal, decomposicaoVal } = getValues();
    const invalidatedItems = validateItems(decomposicaoVal, isUnitaryType);
    if (invalidatedItems.length > 0) return CreateTable(invalidatedItems);

    const isTotalQtdeInvalid = totalQtdeVal !== 100;

    if (!isUnitaryType && isTotalQtdeInvalid)
      return toast.warning('Os percentuais informados não totalizam 100% !');

    if (isUnitaryType && isTotalQtdeInvalid)
      return toast.warning(
        'Os percentuais de custos informados não totalizam 100%!',
      );

    if (isUnitaryType && saldoQtde < 0)
      return toast.warning('Saldo não pode ser negativo.');

    const decomposicaoValData = decomposicaoVal.map((item: any) => {
      item.qtd_decomp = transformAsCurrency(item.qtd_decomp);
      item.val_custo_inicial = transformAsCurrency(item.val_custo_inicial);
      return item;
    });

    const decomposicaoData: CreateDecompositionProps = {
      cod_loja: formData.cod_loja.value,
      cod_produto: formData.cod_produto.value,
      itens: decomposicaoValData,
    };

    const data = await createDecomposition(
      decomposicaoData,
      isUnitaryType ? 1 : 0,
    );

    if (data.success) {
      toast.success(data.message);
      resetFormData();
      setShowSearch(true);
      setUpdateItems(false);
    }

    setLoader(false);
  }, [
    formData.cod_loja.value,
    formData.cod_produto.value,
    isUnitaryType,
    resetFormData,
    saldoQtde,
    getValues,
    tipoFormaExplosao,
  ]);

  const getDecomposicao = async (cod_loja: number, cod_produto: number) => {
    const decomp = await getDecomposition(cod_loja, cod_produto);

    if (decomp && decomp.length > 0) {
      const decompositionCopy = Array.from(decomp);

      const options = decompositionCopy.map((item, idx) => ({
        cod_produto_decompoe: item.cod_produto_decompoe,
        des_produto: item.des_produto,
        des_unidade: item.des_unidade,
        num_sequencia: idx + 1,
        qtd_decomp: item.qtd_decompoe,
        val_custo_inicial: item.val_custo_inicial,
        flg_ipv: item.flg_ipv,
        per_custo_kit: item.per_custo_kit,
      })) as DecomposicaoData[];

      const totalQtd = calculaTotalQtd(options);

      const field = isUnitaryType ? 'per_custo_kit' : 'qtd_decomp';

      const total = calculaTotalSaldoQtde(options, field);
      setValue('totalQtdeVal', totalQtd);
      setTotalQtde(totalQtd);
      setSaldoQtde(100 - total);
      setValue('decomposicaoVal', options);
      setDecomposicao(options);
      setLoader(false);
    } else {
      setValue('totalQtdeVal', 0);
      setTotalQtde(0);
      setSaldoQtde(100);
      setValue('decomposicaoVal', []);
      setDecomposicao([]);
      setLoader(false);
    }
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  useEffect(() => {
    const decompCopy = Array.from(decomposicao);

    const decompUnitaria = decompCopy.filter(
      (decomp) =>
        (decomp.per_custo_kit !== 0 || decomp.per_custo_kit === null) &&
        decomp.val_custo_inicial === 0,
    );

    const decompFracionada = decompCopy.filter(
      (decomp) =>
        (decomp.per_custo_kit === 0 || decomp.per_custo_kit === undefined) &&
        decomp.val_custo_inicial !== 0,
    );

    const decompMapping = decompCopy.map((item) => {
      const unitaria = decompUnitaria.find(
        (unitariaItem) => unitariaItem.num_sequencia === item.num_sequencia,
      );

      const fracionada = decompFracionada.find(
        (fracionadaItem) => fracionadaItem.num_sequencia === item.num_sequencia,
      );

      if (isUnitaryType && unitaria) {
        return {
          ...item,
          per_custo_kit: unitaria.per_custo_kit,
          val_custo_inicial: item.val_custo_inicial,
        };
      }
      if (!isUnitaryType && fracionada) {
        return {
          ...item,
          val_custo_inicial: fracionada.val_custo_inicial,
          per_custo_kit: item.per_custo_kit,
        };
      }

      return item;
    });

    const totalQtd = calculaTotalQtd(decompMapping);

    const field = isUnitaryType ? 'per_custo_kit' : 'qtd_decomp';
    const totalSaldoValue = calculaTotalSaldoQtde(decompMapping, field);

    const totalQtdeValue = isUnitaryType ? totalSaldoValue : totalQtd;

    setValue('totalQtdeVal', totalQtdeValue);
    setTotalQtde(totalQtdeValue);
    setSaldoQtde(100 - totalSaldoValue);

    setValue('total', totalQtdeValue);
    setDecomposicaoUnitaria(decompMapping);
    setDecomposicaoFracionada(decompMapping);
  }, [decomposicao, isUnitaryType, setSaldoQtde, setTotalQtde, setValue]);

  useEffect(() => {
    focusBuscaProduto();
  }, [isUpdate]);

  const focusBuscaProduto = () => {
    if (containerRef.current && isUpdate) {
      const inputBuscaProduto: HTMLInputElement | null =
        containerRef.current.querySelector('[name="produtoBusca"]');
      if (inputBuscaProduto) inputBuscaProduto.focus();
    }
  };

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (buttonAddRef.current && ev.key === 'Enter') {
        buttonAddRef.current.click();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (itemInserido && containerRef.current) {
      const inputBuscaProduto: HTMLInputElement | null =
        containerRef.current.querySelector('[name="produtoBusca"]');

      if (inputBuscaProduto) inputBuscaProduto.focus();
    }
  }, [itemInserido]);

  const handleAddProdutoDecomposicao = (selected: any) => {
    const data = selected.map((item: any) => {
      return {
        label: `${item.cod_produto} - ${item.des_produto}`,
        value: item.cod_produto as string,
        cod_produto_decompoe: item.cod_produto,
        des_produto: item.des_produto,
        des_unidade: item.des_unidade_venda,
        flg_ipv: item.flg_ipv,
        cod_associado: item.cod_associado,
        tipo_movimentacao: item.tipo_movimentacao,
      };
    });

    setValue('produto_busca', data);

    document.getElementById('qtd_decomp')?.focus();
  };

  useEffect(() => {
    if (!!errors.per_custo_kit || !!errors.qtd_decomp) {
      const { per_custo_kit, qtd_decomp } = getValues();
      if (String(per_custo_kit) === '0,00' || String(qtd_decomp) === '0') {
        toast.warning('Quantidade e custo devem ser maiores que zero');
      }
    }
  }, [errors]);

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container ref={containerRef}>
      {showSearch && (
        <Search
          onlyReport
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={209}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={209}
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: formData.cod_produto.value,
            },
          ]}
          title="Cadastro de Decomposição"
          onSave={onSubmit}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => null}
          isDelete={false}
          isNew={false}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            setUpdateItems(false);
            setTipoFormaExplosao(0);
            resetFormData();
          }}
          showSwitch={false}
        >
          <Row>
            <Col md={12} sm={12} className="mt-2">
              <Row md={12} sm={12} className="mt-4">
                <div className={isExplosionPurchase ? 'col-md-8' : 'col-md-12'}>
                  <TableForm />
                </div>
                {isExplosionPurchase && (
                  <div className="col-md-4">
                    <SelectTipoDecomposicao />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
          <Row md={12} sm={12} className="inputsContent">
            <Col md={12} sm={12}>
              <Separator
                labelText="Produtos da Decomposição"
                sidePadding="0 0"
                marginTop="5px"
              />
            </Col>
            <Col className="tamanhoBuscaProduto">
              <BuscaProduto
                id="bProduto"
                tabIndex={0}
                label="Buscar Produto"
                placeholder="Selecione..."
                name="produtoBusca"
                register={register}
                disabled={updateItems}
                isError={!!errors.produtoBusca}
                control={control}
                buscaNasLojas={[Number(formData.cod_loja.value) ?? 1]}
                setValue={setValue}
                customOptions={{
                  hideBuscarPor: true,
                  buscarPor: ['Produto'],
                  showSelecionarItensContendo: true,
                }}
                getProduct={(selected: any) => {
                  handleAddProdutoDecomposicao(selected);
                }}
              />
            </Col>
            <Col className="tamanhoInput">
              <ContainerInput>
                {isUnitaryType && (
                  <>
                    <InputNumber
                      label="Qtde"
                      id="qtd_decomp"
                      min={0}
                      max={100}
                      maxLength={3}
                      placeholder="0"
                      name="qtd_decomp"
                      control={control}
                      register={register}
                      allowsComma={false}
                      isError={!!errors.qtd_decomp}
                      onChange={(val: any) => {
                        if (val.target.value <= 100)
                          setValue('qtd_decomp', val.target.value);
                      }}
                    />

                    <InputPeso
                      label="% Custo"
                      id="per_custo_kit"
                      min={0}
                      max={100}
                      decimals={2}
                      placeholder="0,00"
                      name="per_custo_kit"
                      control={control}
                      register={register}
                      isError={!!errors.per_custo_kit}
                    />
                  </>
                )}
                {!isUnitaryType && (
                  <>
                    <InputPeso
                      label="Qtde %"
                      id="qtd_decomp"
                      min={0}
                      max={100}
                      decimals={3}
                      placeholder="0,000"
                      name="qtd_decomp"
                      control={control}
                      register={register}
                      isError={!!errors.qtd_decomp}
                    />

                    <InputPeso
                      label="Custo Inicial"
                      id="val_custo_inicial"
                      min={0}
                      max={100}
                      decimals={2}
                      placeholder="0,00"
                      name="val_custo_inicial"
                      control={control}
                      register={register}
                      isError={!!errors.val_custo_inicial}
                    />
                  </>
                )}
              </ContainerInput>
            </Col>
          </Row>
          <Row
            md={12}
            sm={12}
            style={{ marginTop: '10px' }}
            className="ContainerButton"
          >
            <Col
              md={12}
              sm={12}
              className="buttonDefault"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ConfirmButton
                onClick={() => {
                  if (updateItems) {
                    handleUpdateProduto();
                  } else {
                    handleAddProduto();
                  }
                }}
              >
                Adicionar
              </ConfirmButton>

              <CancelButton
                onClick={() => {
                  setUpdateItems(false);
                  reset();
                  setValue('decomposicaoVal', decomposicao);
                  setValue('tipo_explosao_compra', tipoFormaExplosao);
                  setValue('isUnitaryType', isUnitaryType);
                  clearErrors('qtd_decomp');
                  clearErrors('val_custo_inicial');
                  clearErrors('produtoBusca');
                  setValue('qtd_decomp', '');
                  setValue('val_custo_inicial', '');
                  setValue('per_custo_kit', '');
                  setValue('produtoBusca', { value: undefined, label: '' });
                  focusBuscaProduto();
                }}
              >
                Cancelar
              </CancelButton>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <ContainerTable>
                {loaderTable && (
                  <div>
                    <Container>
                      <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
                        <Spinner />
                      </div>
                    </Container>
                  </div>
                )}

                <CustomDataGrid
                  rows={
                    isUnitaryType
                      ? decomposicaoUnitaria
                      : decomposicaoFracionada
                  }
                  columns={columns}
                />
                <ContainerTotais>
                  <Row id="linha-item-total">
                    <div className="coluna-item-total">
                      <span>
                        {isUnitaryType ? 'Total %Custo:' : 'Total Qtde:'}
                      </span>
                    </div>
                    <div className="coluna-item-total">
                      {isUnitaryType ? (
                        <span>{formatCurrencyAsText(totalQtde)}</span>
                      ) : (
                        <span>{formatCurrencyAsText(totalQtde, 3)}</span>
                      )}
                    </div>
                    <div className="coluna-item-total">
                      <span>
                        {isUnitaryType ? 'Saldo %Custo:' : 'Saldo Qtde:'}
                      </span>
                    </div>
                    <div className="coluna-item-total">
                      {isUnitaryType ? (
                        <span>{formatCurrencyAsText(saldoQtde)}</span>
                      ) : (
                        <span>{formatCurrencyAsText(saldoQtde, 3)}</span>
                      )}
                    </div>
                  </Row>
                </ContainerTotais>
              </ContainerTable>
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroDeDecomposicaoContent;
