import api from '~/services/api';

import { CreateDecompositionProps } from '../types';

interface ResponseProps {
  message: string;
  success: boolean;
}

export const createDecomposition = async (
  decompData: CreateDecompositionProps,
  formaExplosao: number,
): Promise<ResponseProps> => {
  const { data } = await api.post<ResponseProps>('/cadastro-de-decomposicao', {
    formaExplosao,
    decompData,
  });
  return data;
};
